import $ from 'jquery';

export default function liferay() {

  function viewAll() {
    $('.js-viewall').on('click', function (e){
	  e.preventDefault();
      $(this).addClass('d-none').next('.js-viewless').removeClass('d-none').closest(".js-view-container").find("li").removeClass("d-none");
    });
    $('.js-viewless').on('click', function (e){
	  e.preventDefault();
      var maxElem = $(this).closest(".js-view-container").data("maxelem") || 5;
      $(this).addClass('d-none').prev('.js-viewall').removeClass('d-none').closest(".js-view-container").find(maxElem >= 0 ? "li:gt("+maxElem+")" : "li").addClass("d-none");
    });
  }

  function bookmark() {
    $('.bookmark .bookmark__btn').on('click', function (){
      if(!$(this).parents('.bookmark').hasClass('show-tooltip')) {
        $(this).parents('.bookmark').addClass('show-tooltip').children('.bookmark__info').removeClass('d-none');
      } else {
        $(this).parents('.bookmark').removeClass('show-tooltip').children('.bookmark__info').addClass('d-none');
      }
    });
  }
  
	// Questa funzione permette di aggiungere o rimuovere un bookmark.
    // Evento scatenante: click su elemento con classe bookmark__btn
    // Funzioni chiamate:
    // > _removeContentBookmark(bookmarkId, classPK)
    // > _addContentBookmark(className, classPK, structureId, userId, groupId)
    function initAddOrRemoveContentBookmark(){
        $(".bookmark__btn.bookmark_enabled_js").each(function(){
            $(this).on("click", function(e){
                e.preventDefault();
				e.stopPropagation();
                if($(this).hasClass("bookmark__btn--active")){
                    let bookmarkId = $(this).attr('data-bookmarkId');
                    let classPK = $(this).attr('data-classPK');
                    _removeContentBookmark(bookmarkId, classPK);
                }else{
                    let className = $(this).attr('data-className');
                    let classPK = $(this).attr('data-classPK');
                    let structureId = $(this).attr('data-structureId');
                    let userId = $(this).attr('data-userId');
                    let companyId = $(this).attr('data-companyId');
                    _addContentBookmark(className, classPK, structureId, userId, companyId);
                }
            });
        });
    }
	
	function _addContentBookmark(className, classPK, structureId, userId, companyId){
        $.ajax({
            type: "GET",
            url: "/o/unindustria/manager/bookmark/add-bookmark?className="+className+"&classPK="+classPK+"&userId="+userId+"&companyId="+companyId+"&structureId="+structureId,
            cache: false,
            async: false,
            dataType: "json",
            success: function(data) {
                let bookmarkButtonClassPK = $('button[data-classPK=' + classPK + ']');
                bookmarkButtonClassPK.addClass('bookmark__btn--active');
                bookmarkButtonClassPK.attr("data-bookmarkId", data.bookmarkId);
            },
            error: function(xhr, ajaxOptions, thrownError) {
                if(console){
                    console.log(xhr.status);
                    console.log(thrownError);
                }
            }
        });
    }
	
	function _removeContentBookmark(bookmarkId, classPK){
        $.ajax({
            type: "GET",
            url: "/o/unindustria/manager/bookmark/remove-bookmark?bookmarkId="+bookmarkId,
            cache: false,
            async: false,
            dataType: "json",
            success: function(){
				let bookmarkButtonClassPK = $('button[data-classPK=' + classPK + ']');
				bookmarkButtonClassPK.removeClass('bookmark__btn--active');
            },
            error: function(xhr, thrownError){
                if(console){
                    console.log(xhr);
                    console.log(thrownError);
                }
            }
        });
    }

  $( document ).ready(function(){ 

    if ($('.js-viewall').length > 0) {
      viewAll();
    }

    if ($('.bookmark .bookmark__btn').length > 0) {
      bookmark();
    }
	
	if ($('.bookmark__btn.bookmark_enabled_js').length > 0) {
      initAddOrRemoveContentBookmark();
    }
    
  });
}