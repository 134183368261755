import $ from 'jquery';
import 'jquery.searchable/dist/jquery.searchable-1.1.0.min.js';

export default function filterResults() {
  
	function showAreaWithResults(areaGroup) {
		areaGroup.find('.lista-contatti').each(function () {
			$(this).parents('.section-area').removeClass('d-none');
			if ($(this).children(':visible').length == 0){
				$(this).parents('.section-area').addClass('d-none');
			}
		});
	}

	var searchActive = function(element) {
		// console.log("searchActive");
		if($('#select-area').length > 0) {
			$('#select-area').attr('disabled', 'disabled');
		}
		showAreaWithResults(element);
 	}

	var searchEmpty = function(element) {
		// console.log("searchEmpty");
		if($('#select-area').length > 0) {
			$('#select-area').removeAttr('disabled');
		}
		showAreaWithResults(element);
 	}

  function searchContatto() {
    $('#box-contatti-searchable-container').searchable({
			searchField: '#box-contatti-search',
			selector: '.col-search-box-contatti',
			childSelector: '.searchable',
			onSearchActive: searchActive,
			onSearchEmpty: searchEmpty,
			show: function( elem ) {
				elem.show();
			},
			hide: function( elem ) {
				elem.hide();
			}
		});
  }
  
  $( document ).ready(function() {
    if($('#box-contatti-searchable-container').length > 0) {
			searchContatto();
    }
  });
  
}