import $ from 'jquery';

export default function accordion() {
  
  function init() {
    $('.js-accordion .js-accordion__toggle').on('click', function(e) {
      if(!$(this).parents('.js-accordion').hasClass('open')) {
        $(this).parents('.js-accordion').addClass('open');
      } else {
        $(this).parents('.js-accordion').removeClass('open');
      }
    });
  }
  
  $( document ).ready(function() {
    if($('.js-accordion .js-accordion__toggle').length > 0) {
      init();
    }
  });
}