import $ from 'jquery';
import cookie from 'jquery.cookie';

export default function cookieBanner() {

	function init() {
		$(window).on('click', function(e) {
			if ($(e.target).is('a[href="/privacy"]')) {
				console.log("si va a privacy");
				$.removeCookie('UnindustriaCookieConsent', { path: '/' });
			} else {
				if (!($.cookie("UnindustriaCookieConsent"))) {
					$.cookie('UnindustriaCookieConsent', '1', { expires: 730, path: '/' });
					console.log("il cookie consent è: " + $.cookie('UnindustriaCookieConsent'));
				}
				$('.banner-wrapper').hide();
			}
		});
		$('#banner-privacy').show();
	};

	$( document ).ready(function() {
		if (!($.cookie("UnindustriaCookieConsent"))) {
			init();
		}
	});
}