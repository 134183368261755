import $ from 'jquery';
import 'slick-carousel';

export default function slider() {
  
  function sliderHero() {
    var $heroSlider = $('.slider-hero');

    if ($heroSlider.length) {
      var currentSlide;
      var slidesCount;
      var sliderCounter = document.createElement('div');
      sliderCounter.classList.add('counter');
      
      var updateSliderCounter = function(slick, currentIndex) {
        currentSlide = slick.slickCurrentSlide() + 1;
        slidesCount = slick.slideCount;
        $(sliderCounter).text(currentSlide + ' di ' +slidesCount)
      };

      $heroSlider.on('init', function(event, slick) {
        $heroSlider.append(sliderCounter);
        updateSliderCounter(slick);
      });

      $heroSlider.on('afterChange', function(event, slick, currentSlide) {
        updateSliderCounter(slick, currentSlide);
      });

      $heroSlider.slick({
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: false,
        fade: true,
        speed: 1000,
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              fade: false
            }
          }
        ]
      });
    }
  }

  function sliderAppuntamenti() {
    $('.slider-appuntamenti').slick({
      arrows: true,
      dots: false,
      infinite: true,
      autoplay: false,
      slidesToShow: 3,
      initialSlide: 1,
      centerMode: true,
      centerPadding: '5%',
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            initialSlide: 0,
            centerPadding: '15%',
          }
        }
      ]
    });
  }

  function sliderProgetti() {
    $('.slider-progetti').slick({
      arrows: true,
      dots: false,
      infinite: true,
      autoplay: false,
      slidesToShow: 2,
      initialSlide: 1,
      centerMode: true,
      centerPadding: '5%',
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            initialSlide: 0,
            centerPadding: '15%',
          }
        }
      ]
    });
  }

  function sliderNewsImprese() {
    $('.slider-news-imprese').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerPadding: '15%',
          }
        }
      ]
    });
  }

  function sliderGuide() {
    $('.slider-guide').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerPadding: '15%',
          }
        }
      ]
    });
  }

  function sliderNewsDetail() {
    $('.slider-photogallery-detail').slick({
      arrows: true,
      dots: true,
      infinite: true,
      autoplay: false,
      slidesToShow: 1,
      adaptiveHeight: true
    });
  }
  
  $( document ).ready(function() {
    if($('.slider-hero').length > 0) {
      sliderHero();
    }

    if($('.slider-appuntamenti').length > 0) {
      sliderAppuntamenti();
    }

    if($('.slider-progetti').length > 0) {
      sliderProgetti();
    }

    if($('.slider-news-imprese').length > 0) {
      sliderNewsImprese();
    }

    if($('.slider-guide').length > 0) {
      sliderGuide();
    }

    if($('.slider-photogallery-detail').length > 0) {
      sliderNewsDetail();
    }
  });
}