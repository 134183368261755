import $ from 'jquery';

export default function subscribe() {
  
  function init() {
		$(document).on("click", "#btn-subscribe",function(e){
			e.preventDefault();
			let crmId= $('.unindustria-subscribe__label.subscribe').attr('data-crmid');
			let bulkEmailTypeId = $('.unindustria-subscribe__link').last().attr('data-bulkEmailTypeId');
			console.log("crmId: " + crmId);
			console.log("bulkEmailTypeId: " + bulkEmailTypeId);
			if(crmId && bulkEmailTypeId) {
				$.ajax({
					type: "GET",
					url: "/o/unindustria/crm/service/subscribe-topic-crm?crmId="+crmId+"&bulkEmailTypeId="+bulkEmailTypeId,
					cache: false,
					async: false,
					dataType: "json",
					success: function (data) {
						if (data.results == 'ok' && data.topicId) {
							console.log("Success subscribe topic method...");
							console.log("topicId: " + data.topicId)
							$(".unindustria-subscribe__label.unsubscribe").last().attr("data-topicid", data.topicId);
							$(".unindustria-subscribe__label.unsubscribe").last().removeClass("d-none");
							$(".unindustria-subscribe__label.subscribe").last().addClass("d-none");
							$(".ico-svg.unsubscribe").last().removeClass("d-none");
							$(".ico-svg.subscribe").last().addClass("d-none");
							$('.unindustria-subscribe__link').last().attr("id", "btn-unsubscribe");
						}else if(data.results == 'ok' && data.refresh == 'true'){
							document.location.reload();
						}else{
							console.log("Error subscribe topic method...");
						}
					},
					error: function (xhr, ajaxOptions, thrownError) {
						console.log("Error subscribe topic method...");
					}
				});
			}
		
		});
						
		$(document).on("click", "#btn-unsubscribe",function(e){
			e.preventDefault();
			let topicId= $(".unindustria-subscribe__label.unsubscribe").last().attr("data-topicid");
			console.log("topicId: " + topicId);
			if(topicId) {
				$.ajax({
						type: "GET",
						url: "/o/unindustria/crm/service/unsubscribe-topic-crm?topicId="+topicId,
						cache: false,
						async: false,
						dataType: "json",
						success: function (data) {
							if (data.results == 'ok') {
								console.log("Success unsubscribe topic method...");
								$(".unindustria-subscribe__label.unsubscribe").last().attr("data-topicid", "");
								$(".unindustria-subscribe__label.unsubscribe").last().addClass("d-none");
								$(".unindustria-subscribe__label.subscribe").last().removeClass("d-none");
								$(".ico-svg.unsubscribe").last().addClass("d-none");
								$(".ico-svg.subscribe").last().removeClass("d-none");
								$('.unindustria-subscribe__link').last().attr("id", "btn-subscribe");
							}else{
								console.log("Error subscribe topic method...");
							}
						},
						error: function (xhr, ajaxOptions, thrownError) {
							console.log("Error unsubscribe topic method...");
						}
				});
			}
		});
  }

  $( document ).ready(function() {
    
   if($('#subscribe-topic')) {
      init();
    }

  });
}