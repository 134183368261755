import $ from 'jquery';

export default function stickyNav() {
  
  function init() {
    let header = document.querySelector(".header-container").offsetHeight;
    let mainNavLinks = document.querySelectorAll(".webcontent-detail nav ul li a");
    
    window.addEventListener("scroll", event => {
      let fromTop = window.scrollY - header;

      mainNavLinks.forEach(link => {
        let section = document.querySelector(link.hash);

        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    });
  }
  
  $( document ).ready(function() {
    if($('.webcontent-detail').length > 0) {
      init();
    }
  });
}