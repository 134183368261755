import $ from 'jquery';

export default function searchInSite() {
  
  function init() {
    $('.js-search-open').on('click', function() {
      $('.header-container .header-container__search').addClass('open');
    });

    $('.js-search-close').on('click', function() {
      $('.header-container .header-container__search').removeClass('open');
    });
  }
  
  $( document ).ready(function() {
    if($('.js-search-open').length > 0) {
      init();
    }
  });
  
}