import './polyfill/currentScript'
import 'core-js/stable' 
import 'regenerator-runtime/runtime'

import utils from './utils'
import navigation from './navigation'
import hamburger from './hamburger'
import slider from './slider'
import accordion from './js-accordion'
import indiceAZ from './indice-az'
import prototipo from './prototipo'
import selectArea from './select-area'
import filterResults from './searchable'
import lightbox from './lightbox'
import stickyNav from './sticky-nav'
import searchInSite from './search'
import subscribe from './subscribe'
import datepicker from './datepicker'
import navChild from './nav-child'
import cookieBanner from './cookie-banner'
import liferay from './liferay'

const bundle_url = new URL(document.currentScript.src);
__webpack_public_path__ = bundle_url.pathname.substring(0, bundle_url.pathname.lastIndexOf("/")+1);

navigation();
hamburger();
slider();
accordion();
indiceAZ();
utils();
selectArea();
filterResults();
lightbox();
stickyNav();
searchInSite();
subscribe();
navChild();
cookieBanner();

if (!process.env.LIFERAY_ENV) {
  prototipo();
  datepicker();
}

if (process.env.LIFERAY_ENV) {
  liferay();
}