import $ from 'jquery';

export default function navChild() {
  
  function init() {
    $('.nav-child .nav-child__btn').on('click', function(e) {
      if(!$(this).parents('.nav-child').hasClass('open')) {
        $(this).parents('.nav-child').addClass('open');
      } else {
        $(this).parents('.nav-child').removeClass('open');
      }
    });
  }
  
  $( document ).ready(function() {
    if($('.nav-child .nav-child__btn').length > 0) {
      init();
    }
  });
}