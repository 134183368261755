import $ from 'jquery';

export default function navigation() {
  
  function init() {
    $('.unindustria-navigation .unindustria-navigation__item span.unindustria-navigation__link').on('click', function() {
      const windowWidth = $(window).width();
      const megamenuHeight = $(this).next('.unindustria-navigation__megamenu').outerHeight() + 100;
      // console.log("windowWidth: ", windowWidth);

      if(!$(this).parents('.unindustria-navigation__item').hasClass('open')) {
        $('.unindustria-navigation .unindustria-navigation__item').removeClass('open');
        $(this).parents('.unindustria-navigation__item').addClass('open');
        if(windowWidth > 991) {
          $('.header-container').addClass('open');
        }
        $('.header-container__bg-megamenu').height(megamenuHeight);
      } else {
        $(this).parents('.unindustria-navigation__item').removeClass('open');
        if(windowWidth > 991) {
          $('.header-container').removeClass('open');
        }
        $('.header-container__bg-megamenu').height("auto");
      }
    });
  }
  
  $( document ).ready(function() {
    if($('.unindustria-navigation .unindustria-navigation__item span.unindustria-navigation__link').length > 0) {
      init();
    }
  });
  
}