import $ from 'jquery';

export default function indiceAZ() {

  function init() {
    $('.unindustria-indice .unindustria-indice__list .unindustria-indice__item[data-evidenza="false"]').addClass('d-none');
    
    $('.unindustria-indice .unindustria-indice__letters button').on('click', function() {
      const letter = $(this).data('letter');

      if(!$(this).hasClass('active')) {

        $('.unindustria-indice .unindustria-indice__letters button').removeClass('active');
        $(this).addClass('active');
        $('.unindustria-indice .unindustria-indice__title').html('<span class="text-uppercase">'+letter+'</span>');
        $('.unindustria-indice .unindustria-indice__list .unindustria-indice__item').removeClass('d-none');
        $('.unindustria-indice .unindustria-indice__list .unindustria-indice__item:not([data-letter="'+letter+'"])').addClass('d-none');
      
      } else {

        $('.unindustria-indice .unindustria-indice__letters button').removeClass('active');
        let label = 'In evidenza';
        if (process.env.LIFERAY_ENV) {
          label =  $('.unindustria-indice .unindustria-indice__title').data('key');
        }
        $('.unindustria-indice .unindustria-indice__title').html(label);
        $('.unindustria-indice .unindustria-indice__list .unindustria-indice__item').removeClass('d-none');
        $('.unindustria-indice .unindustria-indice__list .unindustria-indice__item[data-evidenza="false"]').addClass('d-none');
      }
      
    });
  }

  $( document ).ready(function() {
    if ($('.unindustria-indice').length > 0){
      init();
    }
  });
}