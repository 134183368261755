import $ from 'jquery';

export default function selectArea() {
  
  function init() {
    $('#select-area').on('change', function() {
      var area = $(this).val();
      if( !area ) {
        //nessuna area selezionata
        $('.section-area').removeClass('d-none');
        $('#box-contatti-search').removeAttr('disabled').parents('.search-form').removeClass('search-form--disabled');
      } else {
        $('#box-contatti-search').val('').attr('disabled', 'disabled').parents('.search-form').addClass('search-form--disabled');
        $('.section-area').addClass('d-none');
        $('#'+area).removeClass('d-none');
      }
    });
  }
  
  $( document ).ready(function() {
    if($('#select-area').length > 0) {
      init();
    }
  });
}