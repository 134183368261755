import $ from 'jquery';

export default function hamburger() {
  
  function init() {
    $('.unindustria-hamburger').on('click', function() {

      if(!$(this).hasClass('open')) {
        $(this).addClass('open');
        $('.header-container').addClass('open');
        $('.unindustria-navigation').addClass('open');
      } else {
        $(this).removeClass('open');
        $('.header-container').removeClass('open');
        $('.unindustria-navigation, .unindustria-navigation .unindustria-navigation__item').removeClass('open');
      }

    });
  }
  
  $( document ).ready(function() {
    if($('.unindustria-hamburger').length > 0) {
      init();
    }
  });
  
}